$(document).ready(function(){


    // Register View bio click: Grab image and bio, and take it to the modal
    $('.block--contacts-no-image .read-more').on('click', function(e){
        e.preventDefault();

        const id = $(this).attr('data-member-id');

        const img = $(`#member-${id} .member--image`);
        const name = $(`#member-${id} .member--name`);
        const job_title = $(`#member-${id} .member--job-title`);
        const bio = $(`#member-${id} .member--bio`);
        const email = $(`#member-${id} .member--email`);

        if ( img.length > 0 ){
            $('#peopleBioModal .modal--image').removeClass('d-none');
            $('#peopleBioModal .modal--image').html(img.clone().removeClass('d-none').removeClass('w-100'));
        }else{
            $('#peopleBioModal .modal--image').addClass('d-none');
        }
        
        $('#peopleBioModal .modal--name').html(name.html());
        $('#peopleBioModal .modal--job-title').html(job_title.html());                
        $('#peopleBioModal a').attr('href', 'mailto:' + email.html());
        $('#peopleBioModal .modal--bio').html(bio.html());
    
    });

});